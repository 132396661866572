<template>
  <div
    v-if="selectedUnits.length > 1"
    class="SingleUnitSelectedText">
    <span>{{ text }}</span>
    <span>-</span>
    <span
      class="ChangeOption"
      @click="showUnitSelectionModal = true">{{ mixWB('CHANGE').toLowerCase() }}</span><div/>

    <Dialog
      :isShowing="showUnitSelectionModal"
      :useComponent="UnitSelectionModal"
      @close="showUnitSelectionModal = false"
      @see-all-click="onSeeAllUnitsClick"
      @unit-click="onUnitClick" />
  </div>
</template>

<script>
import Dialog from '@/components/Modals/Dialog.vue'
import UnitSelectionModal from '@/components/ScreeningItems/UnitSelectionModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SingleUnitSelectedText',
  data() {
    return {
      UnitSelectionModal,
      showUnitSelectionModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningSelectedFilterUnitID',
      'selectedUnits',
    ]),
    text() {
      if (!this.currentScreeningSelectedFilterUnitID) {
        return this.mixWB('ALL_UNITS')
      }

      const unit = this.selectedUnits.find(
        (x) => x.id === this.currentScreeningSelectedFilterUnitID,
      )

      return this.mixWB('ONLY_X_2', [unit.getFullTitle().toLowerCase()])
    },
  },
  methods: {
    onSeeAllUnitsClick() {
      this.showUnitSelectionModal = false
      this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', null)
    },
    onUnitClick(unitID) {
      this.showUnitSelectionModal = false
      this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', unitID)
    },
  },
  components: {
    Dialog,
  },
}
</script>

<style lang="stylus" scoped>
  .SingleUnitSelectedText
    display flex
    gap 6px
    span
      color $color_grey
    .ChangeOption
      text-decoration underline
      cursor pointer
      &:hover
        text-decoration none
</style>
