<template>
  <div
    class="PCBScreeningSchema"
    :class="{IsDone: isDone}">
    <div
      class="QuestionWrap"
      v-for="(question, index) in pcbScreeningQuestionsMutated"
      :key="question.id">
      <span class="QuestionTitle">#{{ index + 1 }}: {{ mixWB(question.translation) }}</span>
      <ButtonList
        :groupName="question.id"
        :buttons="question.buttons"
        layout="even"
        @button-click="onButtonListClick" />
      <InputField
        :name="question.id"
        :value="question.comment"
        :placeholder="mixWB('COMMENTS')"
        @blur="onCommentUpdate" />
    </div>

    <Button
      :text="buttonText"
      :isLoading="isButtonLoading"
      @button-click="onSaveClick"  />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty, clone } from 'lodash-es'
import InputField from '@/components/FormElements/InputField.vue'
import ButtonList from '@/components/FormElements/ButtonList.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'PCBScreeningSchema',
  props: {
    formName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttons: [
        {
          id: 1,
          text: this.mixWB('YES'),
        },
        {
          id: 0,
          text: this.mixWB('NO'),
        },
        {
          id: 2,
          text: this.mixWB('DONT_KNOW'),
        },
      ],
      dataToSave: {},
      needsTesting: null,
      isButtonLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'pcbScreeningQuestions',
      'screeningPCBScreenings',
    ]),
    isDone() {
      return !!this.screeningPCBScreenings.find((x) => x.id === this.formName)
    },
    pcbScreeningQuestionsMutated() {
      if (isEmpty(this.dataToSave)) {
        return []
      }

      return this.pcbScreeningQuestions.reduce((prev, question) => {
        const mutatedQuestion = clone(question)

        mutatedQuestion.buttons = this.buttons.reduce((prevy, button) => {
          const buttonCopy = clone(button)
          buttonCopy.isActive = button.id === this.dataToSave[question.id].answer
          prevy.push(buttonCopy)
          return prevy
        }, [])

        mutatedQuestion.comment = this.dataToSave[question.id].comment

        prev.push(mutatedQuestion)

        return prev
      }, [])
    },
    buttonText() {
      return this.isDone ? this.mixWB('SAVE_AGAIN') : this.mixWB('SAVE')
    },
  },
  methods: {
    onButtonListClick({ groupName: questionID, buttonID: answer }) {
      this.dataToSave[questionID].answer = answer
    },
    onCommentUpdate({ name: questionID, value: answer }) {
      this.dataToSave[questionID].comment = answer
    },
    onSaveClick() {
      this.isButtonLoading = true

      setTimeout(() => {
        this.isButtonLoading = false
      }, 250)

      this.updateNeedsTesting()
      this.$emit('save-click', { formName: this.formName, data: this.dataToSave, needsTesting: this.needsTesting })
    },
    updateNeedsTesting() {
      let missingAnswers = false
      let needsTesting = false

      Object.keys(this.dataToSave).forEach((key) => {
        if (missingAnswers) {
          return
        }

        if (this.dataToSave[key].answer === null) {
          missingAnswers = true
          return
        }

        if (needsTesting) {
          return
        }

        if (this.dataToSave[key].answer === 1 || this.dataToSave[key].answer === 2) {
          needsTesting = true
        }
      })

      this.needsTesting = missingAnswers
        ? null
        : needsTesting
    },
    createDataObject() {
      const waitForData = setInterval(() => {
        if (!this.pcbScreeningQuestions) {
          return
        }

        if (!this.screeningPCBScreenings) {
          return
        }

        clearInterval(waitForData)

        const existingScreening = this.screeningPCBScreenings.find((x) => x.id === this.formName)

        this.needsTesting = existingScreening ? existingScreening.needsTesting : null

        this.dataToSave = this.pcbScreeningQuestions.reduce((prev, question) => {
          const existingQuestion = existingScreening
            ? existingScreening.data[question.id]
            : undefined

          if (existingQuestion) {
            prev[question.id] = existingQuestion
          }
          else {
            prev[question.id] = {
              answer: 0,
              comment: '',
            }
          }

          return prev
        }, {})
      }, 100)
    },
  },
  components: {
    ButtonList,
    InputField,
    Button,
  },
  created() {
    this.createDataObject()
  },
}
</script>

<style lang="stylus" scoped>
  .PCBScreeningSchema
    display block
    margin-left -20px
    margin-right -20px
    padding 10px 20px
    &.IsDone
      background-color lighten($color_primary_lightest, 50%)

  .QuestionWrap
    margin-bottom 20px

  .QuestionTitle
    font-size 0.875rem
    display block
    margin-bottom 5px
</style>
