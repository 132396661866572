<template>
  <div class="ScreeningsUpdatePCBScreeningSchema">
    <div class="InnerPage">
      <div class="TitleWrap">
        <span class="PageTitle">{{ mixWB('PCB_SCREENINGS') }}</span>
        <SingleUnitSelectedText />
      </div>

      <CloseButton />

      <div
        class="pcbFormWrap"
        v-for="unit in buildingsToShow"
        :key="unit.id">
        <SectionHeadline :text="unit.getFullTitle()" />
        <PCBScreeningSchema
          :formName="unit.id"
          @save-click="onSaveSchemaClick" />
      </div>

      <NextStepLine
        :isDisabled="!pcbScreeningStatus.isCompleted"
        :showNeedCompleteText="!pcbScreeningStatus.isCompleted"
        showTopBorder />
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import PCBScreeningSchema from '@/components/Forms/PCBScreeningSchema.vue'
import { mapGetters } from 'vuex'
import SingleUnitSelectedText from '@/components/SingleUnitSelectedText.vue'
import NextStepLine from '@/components/ScreeningItems/NextStepLine.vue'

export default {
  name: 'ScreeningsUpdatePCBScreeningSchema',
  computed: {
    ...mapGetters([
      'pcbScreeningStatus',
      'currentScreeningSelectedFilterUnitID',
      'combinedListOfUnits',
      'buildingsInUse',
    ]),
    buildingsToShow() {
      if (!this.currentScreeningSelectedFilterUnitID) {
        return this.buildingsInUse
      }

      const currentUnit = this.combinedListOfUnits.find(
        (x) => x.id === this.currentScreeningSelectedFilterUnitID,
      )
      const currentBuildingID = currentUnit.getBuildingID()

      return this.buildingsInUse.filter((x) => x.id === currentBuildingID)
    },
  },
  methods: {
    onSaveSchemaClick({ formName: unitID, data, needsTesting }) {
      this.$store.dispatch('setScreeningPCBScreening', { unitID, data, needsTesting })
    },
  },
  components: {
    CloseButton,
    SectionHeadline,
    PCBScreeningSchema,
    SingleUnitSelectedText,
    NextStepLine,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdatePCBScreeningSchema
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase

  .pcbFormWrap
    margin-bottom 20px
</style>
